<template>
  <div id="app">
    <About/>
  </div>
</template>

<script>
import About from "./pages/about.vue";

export default {
  name: "App",
  components: {
    About
  }
};
</script>

<style>
</style>
